import { render, staticRenderFns } from "./orderDetail.vue?vue&type=template&id=3b764cb5&"
import script from "./orderDetail.vue?vue&type=script&lang=js&"
export * from "./orderDetail.vue?vue&type=script&lang=js&"
import style0 from "./orderDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-b6e3a4d094/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports