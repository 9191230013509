<template>
	<basePopup cancelText="" okText="" title="주문 항목">
		<h6>주문 항목</h6>
		<vtb :data="orderInf" titleSize="4" />

		<h6 class="mt-6">배송</h6>
		<vtb :data="deliveryInf" titleSize="4">
			<template #date="{ v }">
				<datePicker v-if="!v.disabled" :placeholder="v.title" :model="'input.' + v.key" :pr="ths" />
				<input
					v-else
					class="form-control"
					v-model.trim="v.obj[v.key]"
					:name="v.key"
					:placeholder="v.title"
					:disabled="true"
					type="text"
				/>
			</template>
		</vtb>
		<b-button class="w-1/1 mt-2 px-10 btn-info" @click="edit">수정하기</b-button>

		<div class="flex mt-8">
			<div class="w-1/1">
				<div class="flex">
					<div class="w-1/4 pr-2">
						<h6>상태 변경</h6>
						<vSelect
							v-model="isRefund"
							:clearable="false"
							:disabled="editHistorySeq"
							:options="isRefundOpts"
						/>
					</div>
					<div class="w-1/4 px-1">
						<h6>취소 시 책임</h6>
						<vSelect v-model="responsibilityTarget" :clearable="false" :options="responsibilityOpts" />
					</div>
					<div class="w-1/4 px-1">
						<h6>소비자가 내는 배송비</h6>
						<input
							class="form-control text-right"
							v-model="refundDeliveryPrice"
							@keyup="
								() => {
									refundDeliveryPrice = numFormat(refundDeliveryPrice)
								}
							"
							:disabled="editHistorySeq"
							type="text"
						/>
					</div>
					<div class="w-1/4 pl-2">
						<h6>수량 변경</h6>
						<input
							class="form-control text-right"
							v-model="changeCt"
							@keyup="
								() => {
									changeCt = numFormat(changeCt)
								}
							"
							type="text"
						/>
					</div>
				</div>
				<b-form-textarea class="resize-none mt-2" v-model="memo" placeholder="메모" rows="4" />
			</div>
			<div class="w-40 pl-2 pt-15">
				<b-button v-show="!editHistorySeq" class="w-1/1 mt-2 px-10" @click="cancelRequest">추가</b-button>
				<b-button
					v-show="editHistorySeq"
					class="w-1/1 mt-2 px-10"
					@click="editCancelRequestHistory"
					variant="info"
					>수정</b-button
				>
				<b-button
					v-show="editHistorySeq"
					class="w-1/1 mt-2 px-10"
					@click="editHistorySeq = false"
					variant="gray"
					>취소</b-button
				>
			</div>
		</div>

		<h6 class="mt-6">변경 내역</h6>
		<tb class="" :inf="inf" :pr="ths" :res="res" limit="9999" nav="none" />

		<h6 class="mt-6">쿠폰</h6>
		<vtb :data="couponInf" titleSize="4" />
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

export default {
	props: {
		cName: { default: 'orderPopup' },
		userInfo: { default: '' },
		orderNum: '',
		responsibilityOpts: { default: '' },
	},
	components: { basePopup },
	data() {
		return {
			isShow: false,
			input: {},
			orderInf: [],

			statusOpts: [],
			deliveryCompanyOpts: [],
			isRefundOpts: [],
			deliveryStatusOpts: [],

			editHistorySeq: false,
			isRefund: '',
			responsibilityTarget: '',
			refundDeliveryPrice: '3,000',
			changeCt: '',
			maxCt: 0,
			memo: '',

			isRefundValue: {},
			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '수량 변경', key: 'claimProductCount', size: 4 },
				{ title: '상태 변경', key: 'isRefund', size: 4 },
				{ title: '취소 책임', key: 'responsibilityTarget', size: 4 },
				{ title: '소비자가 내는 배송비', key: 'refundDeliveryPrice', size: 4 },
				{ title: '취소종류', key: 'stateText', size: 4 },
				{ title: '메모', key: 'claimMemo', size: 16, notCenter: 1 },
				{ title: '변경 일시', key: 'updateDate', size: 8 },
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.editHistorySeq = item.seqNo
						this.isRefund = this.isRefundValue
						this.responsibilityTarget = item.responsibilityTarget
						this.refundDeliveryPrice = numFormat(item.refundDeliveryPrice)
						this.changeCt = numFormat(item.claimProductCount)
						this.memo = item.claimMemo
					},
				},
			],
			res: [],

			deliveryInf: [],
			couponInf: [],
		}
	},
	methods: {
		open(item) {
			//팝업 열었을 때 처음 들어오는 부분. 초기화 및 데이터 받아와서 설정
			const isAdmin = this.$acl.check('adminOnly')

			this.editHistorySeq = false
			this.isRefund = this.isRefundOpts[0]
			this.responsibilityTarget = this.responsibilityOpts[0]
			this.refundDeliveryPrice = '3,000'
			this.changeCt = ''
			this.memo = ''

			postApi('orderSelect/orderItemManagementDetail', {
				hasAdminPermission: this.$acl.check('adminOnly'),
				hottOrderNum: this.orderNum,
				productOptionSeq: item.productOptionSeq,
			}).then(res => {
				const {
					availableOrderStatus,
					list,
					orderItemInfoDto,
					orderDeliveryInfoDto,
					couponName,
					couponDiscountPrice,
				} = res
				this.input = { ...item, ...orderDeliveryInfoDto }

				this.statusOpts = availableOrderStatus.map(v => {
					return {
						label: v.name,
						value: v.status,
					}
				})

				//주문 항목 테이블 설정. 관리자 권한일 경우 주문수량, 핫티스트 수수료, 핫트 수수료 필드 수정 가능
				this.orderInf = [
					{ title: '주문번호', cont: this.orderNum },
					{ title: '제품명', cont: item.productName },
					{ title: '주문수량', cont: item.productCount },
					{ title: '제품명 옵션1', cont: item.productOption },
					{ title: '제품명 옵션2', cont: item.productOption },
					{ title: '핫트 할인 부담 금액', couponDiscountPrice },
					{ title: '핫티스트 수수료', cont: orderItemInfoDto.hottistFee },
					{ title: '핫트수수료', cont: orderItemInfoDto.hottFee },
				]
				this.maxCt = orderItemInfoDto.productCount
				this.orderInf.filter(v => v.model).map(v => (v.obj = this.input))

				//변경 내역 데이터 입력
				this.isRefundValue = getOptsFromValue(this.isRefundOpts, res.isRefundValue)
				this.res = getTempPaging(
					list.map(v => {
						v.isRefund = this.isRefundValue?.label
						v.updateDate = v.updateDate.replace('T', ' ')
						v.state = getOptsFromValue(this.deliveryStatusOpts, v.stateCd)
						v.stateText = v.state?.label
						return v
					}),
					list.length
				)

				//배송 테이블 설정. 모든 필드 수정 가능
				this.deliveryInf = [
					{ title: '배송상태', model: 'dropdown', key: 'orderStatus', options: this.statusOpts },
					{ title: '취소일시', model: 'date', key: 'productCancelDate' },
					{ title: '배송출고일', cont: orderDeliveryInfoDto.deliveryStartDate },
					{ title: '운송장등록일', model: 'date', key: 'deliveryRegDate' },
					{ title: '배송사명', model: 'dropdown', key: 'deliveryCompany', options: this.deliveryCompanyOpts },
					{ title: '배송완료일', cont: orderDeliveryInfoDto.deliveryCompleteDate },
					{ title: '운송장번호', model: 'input', key: 'invoiceNum' },
					{ title: '구매확정일', model: 'date', key: 'productCompleteDate' },
				].map(v => {
					v.obj = this.input
					switch (v.key) {
						case 'deliveryCompany':
							v.obj[v.key] = getOptsFromValue(this.deliveryCompanyOpts, v.obj[v.key])
						case 'orderStatus':
						case 'invoiceNum':
							break
						default:
							//관리자가 아닌 경우 위에 나열한 배송상태, 배송사명, 운송장 번호를 제외한 항목은 수정할 수 없다
							v.disabled = !isAdmin
					}
					return v
				})

				//쿠폰 테이블 설정
				this.couponInf = [
					{ title: '쿠폰명', cont: couponName },
					{ title: '할인금액', cont: numFormat(couponDiscountPrice) },
				]

				this.isShow = true
			})
		},
		cancelRequest() {
			//상태 변경부
			let isRefundValue = getDropdownValue(this.isRefund),
				changeCt = num(this.changeCt)
			const refundDeliveryPrice = this.refundDeliveryPrice.replace(/,/g, ''),
				e = []

			if (isRefundValue != '1') {
				if (changeCt) {
					if (changeCt > this.maxCt) e.push('변경 수량은 기존값보다 클 수 없습니다')
					//if (changeCt >= this.maxCt) e.push('변경 수량은 기존값보다 크거나 같을 수 없습니다')
				} else e.push('수량을 확인해주세요')
			} else {
				//전체 취소는 상태값 부분취소, 개수 최대로 진행
				isRefundValue = '2'
				changeCt = this.maxCt
			}

			if (!isNum(refundDeliveryPrice)) e.push('소비자가 내는 배송비에는 숫자만 입력해주세요')

			if (e.length) return alert.w(e)

			postApi('api/backoffice/orderHandle/cancelOrderItem', {
				userOrderProductConnectSeq: this.input.seqNo,
				isRefundValue,
				orderCount: changeCt,
				cancelResponsibilityTargetValue: this.responsibilityTarget,
				refundDeliveryPrice,
				memo: this.memo,
			}).then(() => {
				alert.s('상태 변경이 완료되었습니다')
				refresh()
			})
		},
		editCancelRequestHistory() {
			//변경 내역 -> 수정 -> 상태 변경
			if (!this.editHistorySeq) return
			putApi('api/backoffice/orderHandle/updateOrderItemCancelInfo', {
				orderClaimItemSeq: this.editHistorySeq,
				orderCount: num(this.changeCt),
				cancelResponsibilityTargetValue: this.responsibilityTarget,
				memo: this.memo,
			}).then(() => {
				this.res.list
					.filter(v => (v.seqNo = this.editHistorySeq))
					.map(v => {
						v.claimProductCount = numFormat(this.changeCt)
						v.responsibilityTarget = this.responsibilityTarget
						v.claimMemo = this.memo
					})
				this.editHistorySeq = false
				alert.s('변경내역 수정이 완료되었습니다')
			})
		},
		edit() {
			const input = this.input,
				orderStatus = getDropdownValue(input.orderStatus),
				deliveryCompany = getDropdownValue(input.deliveryCompany),
				invoiceNum = (input.invoiceNum ?? '').replace(/\D/g, ''),
				e = []

			let useInvoiceNum = deliveryCompany || invoiceNum
			//배송사나 운송장 번호를 일단 입력한 경우
			switch (orderStatus) {
				case '6':
				case '배송 중':
				case '7':
				case '배송 완료':
				case '19':
				case '구매확정':
					//혹은 배송중 배송완료 구매확정 상태
					useInvoiceNum = true
			}
			if (useInvoiceNum) {
				if (!deliveryCompany) e.push('배송사를 선택해주세요')
				if (!/^\d{8,16}$/.test(input.invoiceNum)) e.push('운송장 번호를 확인해주세요')
			}
			if (e.length) return alert.w(e)

			const deliveryInfo = {
				deliveryCompany,
				invoiceNum,
			}
			;['deliveryRegDate', 'productCompleteDate', 'productCancelDate'].map(
				k => (deliveryInfo[k] = input[k] ? input[k].get_time() : input[k])
			)

			putApi('api/backoffice/orderHandle/updateOrderItemInfo', {
				hottOrderNum: this.orderNum,
				userOrderProductConnectSeq: input.seqNo,
				deliveryInfo,
				memo: this.memo,
			}).then(() => {
				if (typeof input.orderStatus != 'string')
					//배송상태 수정이 있었다면
					putApi('api/backoffice/orderHandle/updateOrderItemStatus', {
						hottOrderNum: this.orderNum,
						userOrderProductConnectSeq: input.seqNo,
						orderStatus,
					}).then(() => {
						alert.s('수정 완료되었습니다')
						refresh()
					})
				else {
					alert.s('수정 완료되었습니다')
					refresh()
				}
			})
		},

		setNumFormat(v) {
			v.obj[v.key] = numFormat(v.obj[v.key])
		},
	},
	created() {
		//configValue 필요한 항목들 가져오기
		getConfig('delivery_company, is_refund_status, delivery_status', false, false).then(res => {
			this.deliveryCompanyOpts = res.delivery_company
			this.isRefundOpts = res.is_refund_status
			this.deliveryStatusOpts = res.delivery_status
		})
	},
}
</script>
