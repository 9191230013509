<!--주문 관리 페이지 메인-->
<template>
	<div>
		<bCard>
			<div v-if="statusGroup.length == 1">
				<div class="w-80 float-left">
					<h6>주문 상태 변경</h6>
					<vSelect v-model="status" :clearable="false" :options="statusOpts" />
				</div>
				<div class="float-left">
					<h6>&nbsp;</h6>
					<b-button class="px-10 ml-2" @click="updateStatus">변경</b-button>
				</div>

				<vtb
					v-if="status == '취소 완료' || status == '교환 완료' || status == '반품 완료'"
					class="w-1/2 float-right"
					:data="topInf"
					oneColumn
					thClass="w-2/5"
				/>
			</div>

			<p id="floatClear" />
			<h6 class="">주문항목</h6>
			<tb :inf="inf" :res="res" limit="9999" nav="none" />

			<div>
				<div class="btn absolute ml-15 mt-2 z-10" @click.stop.prevent="openMemo">
					<b-icon :icon="'stickies'" font-scale="1" />
				</div>
			</div>
			<div
				v-for="v in [
					{ title: '주문', data: orderInf },
					{ title: '회원', data: userInf },
					{ title: '가격', data: payInf },
					{ title: '렌탈', data: rentalInf },
					{ title: '배송', data: deliveryInf },
					{ title: '계좌', data: bankInf },
				]"
			>
				<h6 class="mt-8 mb-2">{{ v.title }}정보</h6>
				<vtb :data="v.data" titleSize="4" />
			</div>
			<b-button class="block mt-4 w-40 mx-auto btn-info" @click="edit">수정하기</b-button>

			<h6 class="mt-8 mb-2">교환정보</h6>
			<vtb :data="exchangeInf" titleSize="4">
				<template #textarea="{ v }">
					<b-form-textarea
						class="w-1/1 resize-none"
						v-model="v.obj[v.key]"
						placeholder="교환/반품 메모"
						rows="3"
					/>
				</template>
			</vtb>
			<b-button class="block mt-4 w-40 mx-auto btn-primary" @click="updateExchangeInfo"
				>교환정보 수정하기</b-button
			>
		</bCard>
		<orderPopup
			:orderNum="orderNum"
			:pr="ths"
			:responsibilityOpts="this.responsibilityOpts"
			:statusOpts="this.statusOpts"
			:userInfo="origin.userInfo"
		/>
	</div>
</template>

<script>
import orderPopup from 'pages/orderPopup'

export default {
	props: { orderNum: { default: '' } },
	components: { orderPopup },
	data() {
		return {
			statusOpts: [],
			status: '',
			statusGroup: [],
			bankOpts: [],
			responsibilityOpts: ['고객', '배송', '업체', '핫트'],

			origin: {},
			input: {},

			topInf: [],
			inf: [
				{ title: 'No.', model: 'idx', size: 1 },
				{ title: 'seq', key: 'seqNo', size: 3 },
				{
					title: '상품명',
					model: 'button',
					size: 10,
					class: 'w-1/1 text-primary text-left',
					text: v => v.productName,
					bVariant: 'no',
					func: v => {
						this.orderPopup.open(v)
					},
				},
				{ title: '주문수량', key: 'productCount', size: 3 },
				{ title: '할인전금액', key: 'productPrice', size: 4 },

				{ title: '할인금액', key: 'couponDiscountPrice', size: 4 },
				{ title: '할인후가격', key: 'price', size: 4 },
				{ title: '주문상태', key: 'orderStatus', size: 4 },
				{ title: '메모', model: 'memoBtn', domain: 'ORDER_ITEM', size: 2 },
			],
			res: [],

			orderInf: [],
			userInf: [],
			payInf: [],
			rentalInf: [],
			deliveryInf: [],
			bankInf: [],
			exchangeInf: [],
		}
	},
	methods: {
		updateStatus() {
			if (typeof this.status == 'string') return
			putApi('api/backoffice/orderHandle/updateOrderStatus', {
				hasAdminPermission: this.$acl.check('adminOnly'),
				hottOrderNum: this.orderNum,
				orderStatus: getDropdownValue(this.status),
			}).then(() => {
				alert.s('주문 상태 수정에 성공했습니다')
				refresh()
			})
		},
		setNumFormat(v) {
			v.obj[v.key] = numFormat(v.obj[v.key])
		},
		edit() {
			const { userInfo, payInfo, deliveryInfo, refundOrExchangeInfo, orderInf } = this.input,
				data = { orderInf, userInfo, payInfo, deliveryInfo, refundOrExchangeInfo, hottOrderNum: this.orderNum }
			//필수값 확인
			const e = [...checkValid(userInfo, this.userInf), ...checkValid(deliveryInfo, this.deliveryInf)]
			if (e.length) return alert.w(e)
			data.deliveryInfo = this.priceSetNum(deliveryInfo)
			data.refundOrExchangeInfo = this.priceSetNum(refundOrExchangeInfo)
			data.refundOrExchangeInfo.refundBankName = getDropdownValue(data.refundOrExchangeInfo.refundBankName)
			putApi('api/backoffice/orderHandle/updateOrder', data).then(() => {
				alert.s('주문정보 수정에 성공했습니다')
				refresh()
			})
		},
		updateExchangeInfo() {
			//DB 구조상 반품배송비,메모,책임주체 부분은 따로 api를 파야 한다고 함..
			const { refundOrExchangeInfo } = this.input,
				data = { refundOrExchangeInfo, hottOrderNum: this.orderNum }
			data.refundOrExchangeInfo.deliveryPrice = num(data.refundOrExchangeInfo.deliveryPrice)
			data.refundOrExchangeInfo.refundBankName = getDropdownValue(data.refundOrExchangeInfo.refundBankName)
			const e = checkValid(refundOrExchangeInfo, this.exchangeInf)
			if (e.length) return alert.w(e)
			postApi('api/backoffice/orderHandle/updateClaimInfo', data).then(() => {
				alert.s('교환정보 수정에 성공했습니다')
			})
		},
		priceSetNum(v) {
			Object.keys(v).map(k => {
				if (/.*price.*/i.test(k)) v[k] = num(v[k])
			})
			return v
		},

		openMemo() {
			openMemoPopup('USER_ORDER', { orderNum: this.orderNum })
		},
	},
	created() {
		if (!this.orderNum) {
			root.$router.go(-1)
			return alert.w('필수값이 없습니다')
		}

		//configValue 필요한 항목들 가져오기
		getConfig('bankcd', false, false).then(({ bankcd }) => {
			this.bankOpts = bankcd
		})

		postApi('orderSelect/orderManagementDetail', {
			hottOrderNum: this.orderNum,
			hasAdminPermission: this.$acl.check('adminOnly'),
		}).then(res => {
			this.origin = copyVar(res)

			const setNumFormat = {
				refundOrExchangeInfo: ['deliveryPrice'],
				payInfo: ['price', 'couponDiscountPrice'],
				rentalInfo: [
					'price',
					'rentalMonthPrice',
					'rentalPriceInterest',
					'rentalMonthPriceInterest',
					'rentalMonth',
				],
			}
			Object.keys(setNumFormat).map(p => {
				setNumFormat[p].map(k => (res[p][k] = numFormat(res[p][k])))
			})

			const input = this.input,
				{
					list,
					orderInfo,
					productGroupInfo,
					userInfo,
					payInfo,
					deliveryInfo,
					refundOrExchangeInfo,
					rentalInfo,
				} = res
			layout.pageTitle = list[0]?.productGroupName
			Object.keys(res).map(k => (input[k] = res[k]))
			this.statusGroup = res.orderStatus
			this.status = this.statusGroup[0]

			this.topInf = [
				{ title: '책임주체', cont: refundOrExchangeInfo.responsibilityTarget },
				{ title: '교환/반품배송비', cont: refundOrExchangeInfo.deliveryPrice },
				{
					title: '교환반품메모',
					cont: refundOrExchangeInfo.memo,
				},
			]

			this.res = getTempPaging(
				copyVar(list).map(v => {
					v.price = numFormat(v.productPrice - v.couponDiscountPrice)
					v.productPrice = numFormat(v.productPrice)
					v.couponDiscountPrice = numFormat(v.couponDiscountPrice)
					return v
				}),
				list.length
			)

			this.orderInf = [
				{ title: '주문번호', cont: orderInfo.hottOrderNum },
				{ title: '주문일자', cont: orderInfo.orderDate },
				{
					title: '딜번호',
					cont: orderInfo.dealNum == 'self' ? '내돈내산' : orderInfo.dealNum,
				},
				{ title: '장바구니 번호', cont: orderInfo.cartOrderNum },
				{ title: '딜명', cont: orderInfo.dealName },
				{ title: '입점사', cont: productGroupInfo.companyName },
			]

			this.userInf = [
				{ title: '핫트 아이디', cont: userInfo.userId == '-1' ? '비회원' : userInfo.userId },
				{ title: '주문자명', model: 'input', key: 'fromName', format: 'text' },
				{ title: '연락처', model: 'input', key: 'fromPhone', format: 'phone' },
				{ title: '이메일', model: 'input', key: 'email', format: 'email' },
			]
			this.userInf.filter(v => v.model).map(v => (v.obj = input.userInfo))

			this.payInf = [
				{ title: '결제일자', cont: payInfo.payDate },
				{ title: 'PG사 구분', cont: payInfo.pgType },
				{ title: '결제금액', cont: payInfo.price },
				{ title: '할인금액', cont: payInfo.couponDiscountPrice },
				{ title: '결제방법', cont: payInfo.payMethod },
				{ title: '발급쿠폰', cont: payInfo.publishedUserCouponSeq },
				{ title: '간편결제 구분', cont: payInfo.pgSimpleMethod },
			]

			this.rentalInf = [
				{ title: '렌탈 비용', cont: numFormat(rentalInfo.price) },
				{ title: '렌탈 월 비용', cont: numFormat(rentalInfo.rentalMonthPrice) },
				{ title: '렌탈 적용 총 비용', cont: numFormat(rentalInfo.rentalPriceInterest) },
				{ title: '렌탈 적용 월 비용', cont: numFormat(rentalInfo.rentalMonthPriceInterest) },
				{ title: '렌탈 총개월수', cont: rentalInfo.rentalMonth },
				{ title: '상품타입', cont: rentalInfo.productDeliveryType },
			]
			;[
				'deliveryPrice',
				'orderDeliveryPrice',
				'dealDeliveryPrice',
				'orderJejuDeliveryPrice',
				'dealJejuDeliveryPrice',
				'orderEtcDeliveryPrice',
				'dealEtcDeliveryPrice',
			].map(k => (deliveryInfo[k] = numFormat(deliveryInfo[k])))
			this.deliveryInf = [
				{ title: '수령자', model: 'input', key: 'toName', format: 'text' },
				{ title: '개인통관고유번호', cont: deliveryInfo.personalClCode },
				{ title: '수령자 연락처', model: 'input', key: 'toPhone', format: 'phone' },
				{ title: '배송지우편번호', model: 'input', key: 'toPostCode', format: 'num' },
				{ title: '배송지주소', model: 'input', key: 'toAddress', format: 'text' },
				{ title: '배송지 주소 상세', model: 'input', key: 'toAddress2', format: 'text' },
				{ title: '취소일시', cont: deliveryInfo.productCancelDate },
				{ title: '배송메세지', model: 'input', key: 'message' },
				{ title: '배송료 총 합계', cont: deliveryInfo.deliveryPrice },
				{ title: '배송출고일', cont: deliveryInfo.deliveryStartDate },
				{ title: '기본 배송료 합계', cont: deliveryInfo.orderDeliveryPrice },
				{ title: '기본 배송료', cont: deliveryInfo.dealDeliveryPrice },
				{ title: '제주 배송료 합계', cont: deliveryInfo.orderJejuDeliveryPrice },
				{ title: '제주 기본 배송료', cont: deliveryInfo.dealJejuDeliveryPrice },
				{ title: '도서산간 배송료 합계', cont: deliveryInfo.orderEtcDeliveryPrice },
				{ title: '도서산간 기본 배송료', cont: deliveryInfo.dealEtcDeliveryPrice },
				{ title: '수량대비 추가 배송 기준', cont: deliveryInfo.dealBundleDeliveryCount },
				{ title: '배송비 무료 조건', cont: deliveryInfo.dealDeliveryFreePriceText },
			]

			this.deliveryInf.filter(v => v.model).map(v => (v.obj = input.deliveryInfo))

			input.refundOrExchangeInfo.refundBankName = getOptsFromValue(
				this.bankOpts,
				refundOrExchangeInfo.refundBankName
			)
			input.refundOrExchangeInfo.keyy = 1

			this.bankInf = [
				{ title: '환불계좌주', model: 'input', key: 'refundAccountHolder' },
				{ title: '환불은행', model: 'dropdown', key: 'refundBankName', options: this.bankOpts },
				{ title: '환불계좌번호', model: 'input', key: 'refundBankAccountNum' },
			]
			this.bankInf.filter(v => v.model).map(v => (v.obj = input.refundOrExchangeInfo))

			this.exchangeInf = [
				{
					title: '교환/반품 배송비',
					model: 'input',
					key: 'deliveryPrice',
					func: this.setNumFormat,
					format: 'num',
				},
				{
					title: '책임주체',
					model: 'dropdown',
					key: 'responsibilityTarget',
					options: this.responsibilityOpts,
					format: 'text',
				},
				{ title: '교환/반품 메모', model: 'textarea', key: 'memo' },
			]
			this.exchangeInf.filter(v => v.model).map(v => (v.obj = input.refundOrExchangeInfo))

			this.statusOpts = res.availableOrderStatus.map(v => {
				return {
					label: v.name,
					value: v.status,
				}
			})
		})
	},
}
</script>

<style>
#floatClear {
	clear: both;
}

.orderListInnerTable tr:first-child td {
	border-top: none;
}
</style>
